.modal-open .modal {
  display: flex !important;
  align-items: center !important;
  .modal-dialog {
    flex-grow: 1;
  }
}
.modal-sm {
  width: 50%;
  max-width: 500px;
  min-width: 300px;
}
